import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/UsingSparkLayout.js";
import ComponentPreview from '../../../components/ComponentPreview';
import { SprkDivider } from '@sparkdesignsystem/spark-react';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Dictionary`}</h1>
    <p>{`The Dictionary component displays a list of key-value pairs.`}</p>
    <ComponentPreview componentName="dictionary--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Dictionary Example" mdxType="ComponentPreview" />
    <h3>{`Usage`}</h3>
    <p>{`Use the Dictionary component when you have a small
set of data that you want to display using key-value
pairs. If you have a large set of data or more than
one value per key, consider using the Table component
instead.`}</p>
    <h3>{`Guidelines`}</h3>
    <ul>
      <li parentName="ul">{`There should be at least two key-value pairs.`}</li>
      <li parentName="ul">{`The length of the text in a key should not
exceed two lines of wrapped text.`}</li>
      <li parentName="ul">{`A Dictionary cannot be nested within another Dictionary.`}</li>
    </ul>
    <SprkDivider element="span" additionalClasses="sprk-u-Measure" mdxType="SprkDivider"></SprkDivider>
    <h2>{`Variants`}</h2>
    <h3>{`Basic Dictionary`}</h3>
    <p>{`Use the Basic Dictionary when there are fewer than ten rows.`}</p>
    <ComponentPreview componentName="dictionary--default-story" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Basic Dictionary Example" mdxType="ComponentPreview" />
    <h3>{`Striped Dictionary`}</h3>
    <p>{`Use the Striped Dictionary when the number of rows is
greater than ten or when the Dictionary is wide enough
to need an additional visual cue.`}</p>
    <ComponentPreview componentName="dictionary--striped" maxWidth="100%" hasReact hasAngular hasHTML titleAttr="Striped Dictionary Example" mdxType="ComponentPreview" />
    <h2>{`Anatomy`}</h2>
    <ul>
      <li parentName="ul">{`Each row of a Dictionary contains a key on
the left and a value on the right.`}</li>
      <li parentName="ul">{`On small viewports, the value is displayed
below the key.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      